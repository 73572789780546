<script lang="ts" setup></script>

<template>
  <section class="c-cart-card" title="Pagar este item separadamente">
    <div class="cart-card-top">
      <div class="cart-card-patient">
        <div class="cart-card-patient-picture"><slot name="patient-picture" /></div>
        <div class="cart-card-patient-infos">
          <p class="cart-card-patient-name"><slot name="patient-name" /></p>
          <p class="cart-card-patient-cpf"><slot name="patient-cpf" /></p>
        </div>
      </div>
      <div class="cart-card-procedure" v-if="$slots['procedure-items']">
        <p class="cart-card-procedure-title">Procedimento</p>
        <div class="cart-card-procedure-items"><slot name="procedure-items" /></div>
      </div>
      <div class="cart-card-specialist" v-if="$slots['specialist-name']">
        <div class="cart-card-specialist-picture"><slot name="specialist-picture" /></div>
        <div class="cart-card-specialist-infos">
          <p class="cart-card-specialist-name"><slot name="specialist-name" /></p>
          <p class="cart-card-specialist-details"><slot name="specialist-details" /></p>
        </div>
      </div>
    </div>

    <div class="cart-card-bottom">
      <div class="cart-card-establishment">
        <div class="cart-card-establishment-picture"><slot name="establishment-picture" /></div>
        <div class="cart-card-establishment-infos">
          <p class="cart-card-establishment-name"><slot name="establishment-name" /></p>
          <p class="cart-card-establishment-localization"><slot name="establishment-localization" /></p>
        </div>
      </div>
      <div class="cart-card-attendance">
        <div class="cart-card-attendance-date">
          <p class="cart-card-attendance-date-title">Data</p>
          <p class="cart-card-attendance-date-value">
            <i class="fas fa-calendar-alt mr-2" />
            <slot name="attendance-date" />
          </p>
        </div>
        <div class="cart-card-attendance-hour">
          <p class="cart-card-attendance-hour-title">Por ordem de chegada</p>
          <p class="cart-card-attendance-hour-value">
            <i class="fas fa-clock mr-2" />
            <slot name="attendance-hour" />
          </p>
        </div>
        <div class="cart-card-attendance-price">
          <p class="cart-card-attendance-price-title">Preço</p>
          <p class="cart-card-attendance-price-value">
            <slot name="attendance-price" />
          </p>
        </div>
      </div>
    </div>

    <i class="fas fa-trash-can" title="Remover este item do carrinho" @click.stop="$emit('remove')" />
  </section>
</template>

<style lang="scss">
.c-cart-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 16px;
  margin: 0 auto;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: #00000005 0px 1px 3px 0px, #1b1f2326 0px 0px 0px 1px;
  cursor: pointer;
  transition: box-shadow 0.4s, border-color 0.2s;

  .cart-card-top {
    display: flex;
    align-items: center;
    gap: 12px;

    .cart-card-patient {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 320px;
      flex: none;

      .cart-card-patient-infos {
        .cart-card-patient-name {
          color: var(--primary-color);
          font-size: 16px;
          font-weight: 600;
        }

        .cart-card-patient-cpf {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .cart-card-patient-picture {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .cart-card-procedure {
      width: 100%;

      .cart-card-procedure-title {
        font-size: 15px;
        font-weight: 600;
      }

      .cart-card-procedure-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 6px;

        p {
          width: max-content;
          padding: 4px 6px;
          border-radius: 999px;
          border: 1px solid var(--primary-color);
          color: #444;
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
        }
      }
    }

    .cart-card-specialist {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      .cart-card-specialist-infos {
        .cart-card-specialist-name {
          color: var(--primary-color);
          font-size: 16px;
          font-weight: 600;
        }

        .cart-card-specialist-details {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .cart-card-specialist-picture {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .cart-card-bottom {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;

    .cart-card-establishment {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 320px;
      flex: none;

      .cart-card-establishment-picture {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .cart-card-establishment-infos {
        .cart-card-establishment-name {
          color: var(--primary-color);
          font-size: 16px;
          font-weight: 600;
        }

        .cart-card-establishment-localization {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .cart-card-attendance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      width: 100%;
      padding-right: 12%;

      .cart-card-attendance-date-title,
      .cart-card-attendance-hour-title,
      .cart-card-attendance-price-title {
        font-size: 14px;
        font-weight: 600;
      }

      .cart-card-attendance-hour-value,
      .cart-card-attendance-date-value,
      .cart-card-attendance-price-value {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }

      .cart-card-attendance-price-value {
        color: var(--primary-color);
        font-weight: 700;
      }
    }
  }

  .fa-trash-can {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 1.125rem;
    cursor: pointer;
    transition: color 0.2s;
    color: #666;

    &:hover {
      color: var(--danger-color);
    }
  }

  &:hover {
    box-shadow: #0000000d 0px 6px 24px 0px, #00000014 0px 0px 0px 1px;
  }

  &.selected {
    border-color: var(--primary-color);
  }

  @media only screen and (max-width: 900px) {
    .cart-card-bottom {
      flex-direction: column;
      align-items: flex-start;

      .cart-card-attendance {
        padding-right: 4%;
      }
    }
  }

  @media only screen and (max-width: 720px) {
    .cart-card-top {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media only screen and (max-width: 480px) {
    .cart-card-bottom {
      .cart-card-attendance {
        flex-wrap: wrap;
      }
    }
  }
}
</style>
